.hn-input-submit {
  display: flex;
}

.hn-layout-flex {

  .ant-layout,
  &.ant-layout {
    flex-grow: 1;

    .ant-layout-header {
      display: flex;
      justify-content: space-between;
      color: white;
      
    }
  }
  > .ant-layout-header {
    position: fixed;
    width: 100vw;
    z-index: 1;
    height: 48px;
    align-items: center;
  }

  > .ant-layout-content {
    margin-top: 48px;
  }
}

.hn-plain-nav-link {
  color: inherit;
}

.hn-tree {
  width: 100%;

  .ant-tree-treenode {
    width: 100%;
  }

  .ant-tree-node-content-wrapper {
    border: 1px solid #eee;
    width: 100%;
    border-radius: 6px;
  }

  .hn-node-extra {
    display: flex;
    flex-direction: column;
    margin-left: 22px;
    align-items: flex-end;
  }
}

.hn-entity {
  display: inline-flex;
  align-items: center;
  
  > :nth-child(1) {
    margin-right: 0.2em;
  }
}
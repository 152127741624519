#root {
  min-height: 100%;
  display: flex;
}

.App {
  text-align: center;
}

.github-bar {
  background-color: #05264c;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  color: white;
  align-items: center;
  box-shadow: inset 1px 1px 8px 1px black
}